import React from "react"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import { StaticQuery, graphql, Link } from "gatsby"
import Subnavigation from "../components/Subnavigation.tsx"
import Title from "../components/Title.tsx"
import Sticky from "../components/Sticky.tsx"
import Content from "../components/Content.tsx"
import ContactForm from "../components/ContactForm.tsx"
import Layout from "../components/layout"
import { Button } from "../components/Button.tsx"
import SEO from "../components/Seo.js"
import { isMobile } from "react-device-detect"

let DESCRIPTION = {}
let SUBTITLE = {}
let TITLE = {}
let BACKGROUND_IMAGE = {}
let FEATURED_IMAGE = {}
let SUBMENU = []
var PERMALINK = "/team/"

const Team = () => (
  <StaticQuery
    query={graphql`
      query TeamAllTeamQuery {
        allWordpressWpTeam(sort: { fields: [date], order: ASC }) {
          edges {
            node {
              date(formatString: "DD, MMM YYYY")
              title
              slug
              link
              excerpt
              acf {
                subtitle_seo
              }
              featured_media {
                localFile {
                  childImageSharp {
                    id
                    sizes(maxWidth: 850) {
                      ...GatsbyImageSharpSizes
                    }
                  }
                }
              }
            }
          }
        }
        submenu: allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "about" } }
        ) {
          edges {
            node {
              items {
                title
                url
                target
              }
            }
          }
        }
        allWordpressAcfOptions {
          edges {
            node {
              options {
                description_team
                background_image_team {
                  source_url
                }
                featured_image_team {
                  source_url
                }
                subtitle_team
                title_team
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        {data.allWordpressAcfOptions.edges.map(({ node }) => {
          DESCRIPTION = node.options.description_team
          TITLE = node.options.title_team
          SUBTITLE = node.options.subtitle_team
          BACKGROUND_IMAGE = node.options.background_image_team.source_url
          FEATURED_IMAGE = node.options.featured_image_team.source_url
        })}

        {data.allWordpressAcfOptions.edges.map(({ node }) => {
          DESCRIPTION = node.options.description_team
          TITLE = node.options.title_team
          SUBTITLE = node.options.subtitle_team
          BACKGROUND_IMAGE = node.options.background_image_team.source_url
        })}
        <SEO title={TITLE} description={DESCRIPTION} pathname={"/team/"} />

        <Sticky
          backgroundImage={BACKGROUND_IMAGE}
          component={
            <Content
              white
              hideMobile
              background="none"
              title="Make an enquiry"
              paragraphs={["Or call us on +852 2165 1000"]}
            >
              <ContactForm />
            </Content>
          }
        >
          {data.submenu.edges.map(({ node }, i) => {
            SUBMENU = node.items
          })}

          <Subnavigation items={SUBMENU} />

          <Title title={TITLE} subtitle={SUBTITLE} paragraphs={DESCRIPTION} />

          <img src={FEATURED_IMAGE} className="featured" alt="Featured" />

          {data.allWordpressWpTeam.edges.map(({ node }) => (
            <div key={node.slug} className="post center team">
              <img
                src={node.featured_media.localFile.childImageSharp.sizes.src}
                className="panel left"
                alt={node.title}
              />
              <h4 dangerouslySetInnerHTML={{ __html: node.title }} />
              <p
                className="gray"
                dangerouslySetInnerHTML={{ __html: node.acf.subtitle_seo }}
              />
              <div
                className="gray"
                dangerouslySetInnerHTML={{ __html: node.excerpt }}
              />
              <Button
                size="small"
                onClick={() =>
                  node.slug ? (window.location.href = node.slug) : null
                }
              >
                {"Read More"}
              </Button>
            </div>
          ))}
        </Sticky>
      </Layout>
    )}
  />
)

export default Team
