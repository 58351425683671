import React from "react"
import { Form, Field } from "react-final-form"
import classNames from "classnames"
import { isMobileOnly } from "react-device-detect"
import "./ContactForm.scss"
import axios from "axios"

const handleSubmit = async (values: any) => {
  const formDTO = {
    form_id: "1",
    title: "Make an enquiry",
  }

  Object.values(values).forEach((value, index) => {
    formDTO[index + 1] = value
  })

  const options = {
    method: "POST",
    headers: { "content-type": "application/json" },
    auth: {
      username: "ck_95a007b082fc9da2f1bd64b1a77e7a4d7a92c8fb",
      password: "cs_b955418a79f30d120de39413216319ff0d0ba5e6",
    },
    data: formDTO,
    url: "https://wp.ovologroup.com/wp-json/gf/v2/entries/",
  }

  await axios(options)

  alert("Message submitted succesfully!")

  //Clear form and thank the user?
}

const ContactForm = () => (
  <div
    className={classNames("contact-form", {
      mobile: isMobileOnly,
    })}
  >
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, pristine, invalid, form }) => (
        <form
          onSubmit={async values => {
            await handleSubmit(values)
            setTimeout(() => form.reset(), 1)
          }}
          method="post"
          action="https://wp.staging.ovologroup.com/wp-json/gf/v2/entries/"
        >
          <div className="fields">
            <div className="row">
              <div className="col-6">
                <Field
                  name="input_1"
                  component="input"
                  type="text"
                  placeholder="First Name"
                />
              </div>
              <div className="col-6">
                <Field
                  name="input_2"
                  component="input"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <Field
              name="input_3"
              component="input"
              type="email"
              placeholder="Your Email"
            />
            <Field
              name="input_4"
              component="input"
              type="tel"
              placeholder="Telephone"
            />
          </div>
          <div className="fields">
            <Field
              name="input_5"
              component="textarea"
              type="textarea"
              placeholder="Your Message"
              rows={4}
            />
            <button
              type="submit"
              className="btn medium green full"
              disabled={pristine || invalid}
            >
              Send
            </button>
          </div>
        </form>
      )}
    />
  </div>
)

export default ContactForm
